import React from "react";
import { graphql } from "gatsby";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";
import CardMedia from "@material-ui/core/CardMedia";
import { getSrc } from "gatsby-plugin-image";

import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery, useTheme } from "@material-ui/core";

import useAdvert from "../hooks/useAdvert";

import Layout from "../components/Layout";
import NavCard from "../components/NavCard";

const useStyles = makeStyles(() => ({
  root: {
    width: 654,
    margin: 30,
  },
  heading: {
    maxWidth: 654,
    marginTop: 30,
  },
  content: {
    padding: 24,
  },
  advert: {
    marginTop: 24,
    maxWidth: 654,
  },
  advertImg: {
    objectFit: "contain",
    maxWidth: 654,
  },
  helloImg: {
    objectFit: "contain",
  },
}));
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges: choices },
    socialProof: { edges: socialproof },
    hello: { edges: hello },
  },
}) => {
  const cardStyles = useStyles();
  const advert = useAdvert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Layout pageName="home">
      <Container maxWidth="md">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid className={cx(cardStyles.heading)}>
            <Typography
              variant="body1"
              align="justify"
              component="div"
            >
              Valle de Guadalupe is home to{" "}
              <b>stunning wineries</b>, boutique hotels, countryside experiences
              and so much more. Please join us on a{" "}
              <Link to={"/grouptrips"}>
                Group Trip from San Diego
              </Link>{" "}
              or our more private{" "}
              <Link to={"/privatetrips/"}>Group or Couples Tours</Link>.
              <hr />
              We would love to host you in our favorite place: Baja's Valle de
              Guadalupe.
            </Typography>
          </Grid>
          <Grid item xs={12} className={cx(cardStyles.advert)}>
            <Link to={"/grouptrips"}>
              <CardMedia
                className={cx(cardStyles.advertImg)}
                image={isMobile ? advert.sm : advert.lg}
                component="img"
                alt="advertisement"
              />
            </Link>
          </Grid>
          {choices.map(({ node: { frontmatter: c } }, index) => (
            <Grid item key={index}>
              <NavCard info={c} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container maxWidth="md">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          rowSpacing={1}
        >
          {hello.map(({ node: c }, index) => (
            <Grid item key={index}>
              <CardMedia
                className={cardStyles.helloImg}
                image={getSrc(c.childImageSharp)}
                component="img"
                height="450"
                alt="meet the founder"
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
};
export default IndexPage;

export const IndexPageQuery = graphql`
    query IndexPageQuery {
        hello: allFile(
            filter: { sourceInstanceName: { eq: "meet-the-founder" } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
        socialProof: allFile(
            filter: { sourceInstanceName: { eq: "socialproof" } }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData
                    }
                    name
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "home" } } }
            sort: { order: ASC, fields: frontmatter___idx }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        subheader
                        link
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 345
                                    quality: 90
                                    placeholder: TRACED_SVG
                                    tracedSVGOptions: {
                                        color: "#006837"
                                        turdSize: 50
                                        optTolerance: 0.1
                                    }
                                    formats: [WEBP, AVIF, AUTO]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`;
