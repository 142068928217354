import React from "react";
import cx from "clsx";

import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import { getSrc } from "gatsby-plugin-image";
import { useSoftRiseShadowStyles } from "@mui-treasury/styles/shadow/softRise";
import { useN01TextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/n01";
import TextInfoContent from "@mui-treasury/components/content/textInfo";

const slopeImg = makeStyles(() => ({
    root: ({ bgColor = "rgba(0, 0, 0, 0.08)" }) => ({
        width: "100%",
        backgroundPosition: "right 0 top -3rem",
        paddingBottom: "56.25%",
        clipPath: "polygon(0 0, 100% 0%, 100% 84%, 0% 100%)",
        backgroundColor: bgColor,
    }),
}));

const useStyles = makeStyles((theme) => ({
    root: {
        width: 304,
        margin: 30,
        float: "left",
    },
    content: {
        padding: 24,
    },
}));

export default function NavCard({ info }) {
    const cardStyles = useStyles();
    const shadowStyles = useSoftRiseShadowStyles();
    const mediaStyles = slopeImg();

    const textCardContentStyles = useN01TextInfoContentStyles();

    return (
        <Card className={cx(cardStyles.root, shadowStyles.root)}>
            <CardActionArea onClick={() => navigate(info.link)}>
                <CardMedia
                    classes={mediaStyles}
                    image={getSrc(info.image)}
                ></CardMedia>
                <CardContent className={cardStyles.content}>
                    <TextInfoContent
                        classes={textCardContentStyles}
                        heading={info.title}
                        body={info.subheader}
                    />
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
